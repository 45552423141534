<template>
  <div>
    <portal to='modal_header'>
      <h1 class='text-2xl font-semibold px-8 py-8'>업종관리</h1>
    </portal>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>ITEM CODE</label>
      <input type='text' class='form-input' v-model='newdeal.item_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>ITEM NAME</label>
      <input type='text' class='form-input' v-model='newdeal.item_name'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>NEW DEAL TYPE</label>
      <input type='text' class='form-input' v-model='newdeal.newdeal_type'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>THEME NAME</label>
      <input type='text' class='form-input' v-model='newdeal.theme_name'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>FIELD NAME</label>
      <input type='text' class='form-input' v-model='newdeal.field_name'>
    </div>
    <portal to='modal-footer'>
      <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
        <button
          class='border px-6 h-12 text-sm text-white rounded-md font-bold'
          style='background-color: #223645;'
          @click='saveData'>저장</button>
          <button v-if='isUpdate'
            name='show-delete-btn'
            class='p-3 text-red-700
            text-sm border border-transparent hover:border-red-400 rounded-md'
            @click='deleteData'>Delete <span class='font-semibold'>{{newdeal.item_name}}</span></button>
      </div>
    </portal>
  </div>
</template>

<script>
import NewdealApi      from '@/api/v1/newdeal_items'
import cloneDeep       from 'lodash/cloneDeep'

export default {
  name: 'VentureEdit',
  components: {
  },
  props: [
    'target',
    'mode',
  ],
  data () {
    return {
      newdeal : { item_code: '', item_name: '', newdeal_type: '', theme_name: '', field_name: '' }
    }
  },
  computed: {
    isUpdate () {
      return this.mode === 'update'
    },
  },
  watch: {
    'mode': {
      handler: function (newVal) {
        if (newVal === 'add')
          this.newdeal = { item_code: '', item_name: '', newdeal_type: '', theme_name: '', field_name: '' }
        else if (newVal === 'update')
          this.newdeal = cloneDeep(this.target)
      },
      immediate: true,
    },
  },
  methods: {
    saveData () {
      if (this.mode === 'add') {
        NewdealApi.createNewdealItem (this.newdeal).then( resp => {
          this.doneEdit (resp)
        })
      } else {
        NewdealApi.updateNewdealItem (this.newdeal).then( resp => {
          this.doneEdit (resp)
        })
      }
    },
    deleteData () {
      var deleteConfirm = confirm(this.newdeal.name + ' 뉴딜코드가 삭제됩니다.')
      if (!deleteConfirm) {
        return
      }
      NewdealApi.deleteNewdealItem(this.newdeal).then(resp => {
        this.doneEdit (resp)
      })
    },
    doneEdit (resp) {
      this.$emit('done-editing', resp)
    },
  },
  beforeDestroy () {
    this.doneEdit ('')
  },
}
</script>
